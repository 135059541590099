import * as types from './constants';

const INITIAL_STATE = {
  showError: false
};

const updateErrorStatus = (state, action) => ({
  ...state,
  showError: true
});

function loginReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.LOG_IN_FAILED: {
      return updateErrorStatus(state, action);
    }
    default: {
      return state;
    }
  }
}

export default loginReducer;
