import * as types from "./constants";

export const deleteUser = payload => ({
  type: types.DELETE_USER,
  payload
});

export const setDeleteError = payload => ({
  type: types.SET_DELETE_ERROR,
  payload
});

export const changePassword = payload => ({
  type: types.CHANGE_PASSWORD,
  payload
});

export const setPasswordError = payload => ({
  type: types.SET_PASSWORD_ERROR,
  payload
});

export const setSuccessSnackbar = payload => ({
  type: types.SET_SUCCESS_SNACKBAR,
  payload
});
