import React, { Component } from "react";
import Parse from "parse";
import { connect } from "react-redux";
import { compose } from "recompose";
import Login from "./views/Login";
import Layout from "./components/Layout";

import { createStructuredSelector } from "reselect";
import { makeSelectAuth } from "./ducks/selectors";

Parse.initialize(
  "GGoRYuDhntlY4DvTF7MFfEdQ73gGqg9YViQuuGd3",
  null,
  "JK6taIH02WkBaHRzildGYme3fFQYJj40n7XFhDJA"
);
Parse.masterKey = "JK6taIH02WkBaHRzildGYme3fFQYJj40n7XFhDJA";
Parse.serverURL = "https://api.justspeakeasy.com/parse";

const mapStateToProps = createStructuredSelector({
  auth: makeSelectAuth()
});

const mapDispatchToProps = dispatch => ({
  //
});

class App extends Component {
  render() {
    const { auth } = this.props;

    console.log("Auth is: " + auth);

    return <div className="App">{auth ? <Layout /> : <Login />}</div>;
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(App);
