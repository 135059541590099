import { createSelector } from "reselect";

const selectDashState = state => state.dashboardState;

export const makeSelectTotalUsers = () =>
  createSelector(
    selectDashState,
    substate => substate.totalUsers
  );

export const makeSelectMonthlyCampaigns = () =>
  createSelector(
    selectDashState,
    substate => substate.monthlyCampaigns
  );

export const makeSelectMonthlySent = () =>
  createSelector(
    selectDashState,
    substate => substate.monthlySent
  );

export const makeSelectCampaignData = () =>
  createSelector(
    selectDashState,
    substate => substate.campaignData
  );

export const makeSelectRecipientData = () =>
  createSelector(
    selectDashState,
    substate => substate.recipientData
  );
