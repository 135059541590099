import { createSelector } from "reselect";

const selectUserState = state => state.userState;

export const makeSelectDeleteError = () =>
  createSelector(
    selectUserState,
    substate => substate.deleteEmailError
  );

export const makeSelectPasswordError = () =>
  createSelector(
    selectUserState,
    substate => substate.passwordError
  );

export const makeSelectShowSnackbar = () =>
  createSelector(
    selectUserState,
    substate => substate.showSnackbar
  );

export const makeSelectSnackbarMessage = () =>
  createSelector(
    selectUserState,
    substate => substate.snackbarMessage
  );
