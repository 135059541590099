import React, { Component } from "react";
import { colors } from "../../config";
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from "@material-ui/core/styles";

import {
  Dashboard,
  Pricing,
  Users
 } from "../../views";

import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Hidden,
  AppBar,
  IconButton,
  Toolbar
} from "@material-ui/core";
import {
  Dashboard as DashIcon,
  People,
  AttachMoney,
  Menu as MenuIcon
} from "@material-ui/icons";

import { createStructuredSelector } from 'reselect';
import { makeSelectPageNumber } from '../../ducks/selectors';
import { updatePage } from '../../ducks/actions';

import Logo from "../images/speak_easy_logo_white.png";

const mapStateToProps = createStructuredSelector({
  pageNumber: makeSelectPageNumber()
});

const mapDispatchToProps = dispatch => ({
  changePageNumber: page => dispatch(updatePage(page))
});

const drawerWidth = 245;

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    width: "100%"
  },
  drawerPaper: {
    position: "fixed",
    width: drawerWidth,
    backgroundColor: colors.primaryDark
  },
  content: {
    backgroundColor: "#FAFAFA",
    position: "relative",
    float: "right",
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    minHeight: "100vh"
  },
  item: {
    color: "#ffffff"
  },
  bottomList: {
    position: "absolute",
    bottom: 0,
    width: "100%"
  },
  appBar: {
    position: "absolute",
    backgroundColor: colors.primaryDark
  },
  headerLogo: {
    color: "#FAFAFA"
  },
  imgBackground: {
    paddingRight: 10,
    paddingLeft: 10,
    textAlign: 'center'
  },
  image: {
    width: '65%'
  }
});

class Layout extends Component {

  state = {
    mobileOpen: false
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            classes={{
              paper: classes.drawerPaper
            }}
          >
            <div className={classes.imgBackground}>
              <img className={classes.image} src={Logo} />
            </div>
            <List>
              <ListItem button onClick={() => this.props.changePageNumber(1)}>
                <ListItemIcon
                  classes={{
                    root: classes.item
                  }}
                >
                  <DashIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Dashboard"
                  classes={{
                    primary: classes.item
                  }}
                />
              </ListItem>
              <ListItem button onClick={() => this.props.changePageNumber(2)}>
                <ListItemIcon
                  classes={{
                    root: classes.item
                  }}
                >
                  <People />
                </ListItemIcon>
                <ListItemText
                  primary="Users"
                  classes={{
                    primary: classes.item
                  }}
                />
              </ListItem>
              <ListItem button onClick={() => this.props.changePageNumber(3)}>
                <ListItemIcon
                  classes={{
                    root: classes.item
                  }}
                >
                  <AttachMoney />
                </ListItemIcon>
                <ListItemText
                  primary="Pricing"
                  classes={{
                    primary: classes.item
                  }}
                />
              </ListItem>
            </List>
          </Drawer>
        </Hidden>
        <Hidden mdUp>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerToggle}
                className={classes.navIconHide}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="title" color="inherit" noWrap>
                SpeakEasy
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            classes={{
              paper: classes.drawerPaper
            }}
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
          >

            <List>
              <ListItem button onClick={() => this.props.changePageNumber(1)}>
                <ListItemIcon
                  classes={{
                    root: classes.item
                  }}
                >
                  <DashIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Dashboard"
                  classes={{
                    primary: classes.item
                  }}
                />
              </ListItem>
              <ListItem button onClick={() => this.props.changePageNumber(2)}>
                <ListItemIcon
                  classes={{
                    root: classes.item
                  }}
                >
                  <People />
                </ListItemIcon>
                <ListItemText
                  primary="Users"
                  classes={{
                    primary: classes.item
                  }}
                />
              </ListItem>
              <ListItem button onClick={() => this.props.changePageNumber(3)}>
                <ListItemIcon
                  classes={{
                    root: classes.item
                  }}
                >
                  <AttachMoney />
                </ListItemIcon>
                <ListItemText
                  primary="Pricing"
                  classes={{
                    primary: classes.item
                  }}
                />
              </ListItem>
            </List>
          </Drawer>
        </Hidden>
        <main className={classes.content}>
          {this.props.pageNumber === 1 && <Dashboard />}
          {this.props.pageNumber === 2 && <Users />}
          {this.props.pageNumber === 3 && <Pricing />}
        </main>
      </div>
    );
  }
}
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(withStyles(styles)(Layout));
