import * as types from "./constants";

export const initialLoad = () => ({
  type: types.LOAD_DATA
});

export const setInitial = payload => ({
  type: types.SET_INITIAL,
  payload
});

export const getCampaignData = payload => ({
  type: types.GET_CAMPAIGN_DATA,
  payload
});

export const setCampaignData = payload => ({
  type: types.SET_CAMPAIGN_DATA,
  payload
})

export const setRecipientData = payload => ({
  type: types.SET_RECIPIENT_DATA,
  payload
})
