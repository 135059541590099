import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '../../config';

import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';

import {
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon
} from '@material-ui/icons';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  content: {
    backgroundColor: colors.success
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  closeIcon: {
    fontSize: 20
  },
  snackbar: {
    marginBottom: 50
  }
});

class SuccessSnackbar extends Component {
  render() {
    const { classes, open, onClose, message } = this.props;
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={5000}
        onClose={onClose}
        className={classes.snackbar}
      >
        <SnackbarContent
          className={classes.content}
          message={
            <span className={classes.message}>
              <CheckCircleIcon className={classes.icon} />
              {message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={onClose}
            >
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          ]}
        />
      </Snackbar>
    );
  }
}

export default withStyles(styles)(SuccessSnackbar);
