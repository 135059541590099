import * as types from './constants';

export const login = payload => ({
  type: types.LOG_IN,
  payload
});

export const loginFailed = () => ({
  type: types.LOG_IN_FAILED
});
