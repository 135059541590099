import * as types from "./constants";

const INITIAL_STATE = {
  deleteEmailError: false,
  passwordError: false,
  showSnackbar: false,
  snackbarMessage: ""
};

const deleteError = (state, action) => ({
  ...state,
  deleteEmailError: action.payload
});

const passwordError = (state, action) => ({
  ...state,
  passwordError: action.payload
});

const setSnackbar = (state, action) => ({
  ...state,
  showSnackbar: action.payload.show,
  snackbarMessage: action.payload.message
});

function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_DELETE_ERROR: {
      return deleteError(state, action);
    }
    case types.SET_PASSWORD_ERROR: {
      return passwordError(state, action);
    }
    case types.SET_SUCCESS_SNACKBAR: {
      return setSnackbar(state, action);
    }
    default: {
      return state;
    }
  }
}

export default userReducer;
