export const colors = {
  primary: '#102BD3',
  primaryDark: '#0000a5',
  primaryLight: '#6553ff',
  secondary: '#A7A9AC',
  secondaryDark: '#616161',
  secondaryLight: '#baffff',
  error: '#F44336',
  success: '#4CAF50',
  white: '#FFFFFF'
};
