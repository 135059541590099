import { call, put, takeLatest } from "redux-saga/effects";
import ParseReact from "parse-react";
import Parse from "parse";
import moment from "moment";

import { LOAD_DATA, GET_CAMPAIGN_DATA } from "./constants";
import { setInitial, setCampaignData, setRecipientData } from "./actions";

function getSunday(d) {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day; // adjust when day is sunday
  return new Date(d.setDate(diff));
}

const countUsers = async () => {
  const query = new Parse.Query(Parse.User);
  var count = await query.count({ useMasterKey: true });
  return count;
};

const monthlyCampaignCount = async () => {
  var date = new Date();
  var month = date.getMonth();
  var year = date.getFullYear();
  var firstOfMonth = new Date(year, month, 1);

  const Campaign = new Parse.Object.extend("offer");
  const query = new Parse.Query(Campaign);
  query.greaterThanOrEqualTo("createdAt", firstOfMonth);
  var count = await query.count({ useMasterKey: true });
  return count;
};

const monthlySentCount = async () => {
  var date = new Date();
  var month = date.getMonth();
  var year = date.getFullYear();
  var firstOfMonth = new Date(year, month, 1);

  const Recipient = new Parse.Object.extend("recipients");
  const query = new Parse.Query(Recipient);
  query.greaterThanOrEqualTo("createdAt", firstOfMonth);
  var count = await query.count({ useMasterKey: true });
  return count;
};

const getWeeklyCampaigns = async () => {
  var date = getSunday(new Date());
  date.setUTCHours(0, 0, 0, 0);
  var data = [];
  for (var x = 0; x < 7; x++) {
    var today;
    var day = "Sunday";
    switch (x) {
      case 0:
        today = date;
        day = "Sunday";
        break;
      case 1:
        day = "Monday";
        today = new Date(date.setTime(date.getTime() + 1 * 86400000));
        break;
      case 2:
        day = "Tuesday";
        today = new Date(date.setTime(date.getTime() + 1 * 86400000));
        break;
      case 3:
        day = "Wednesday";
        today = new Date(date.setTime(date.getTime() + 1 * 86400000));
        break;
      case 4:
        day = "Thursday";
        today = new Date(date.setTime(date.getTime() + 1 * 86400000));
        break;
      case 5:
        day = "Friday";
        today = new Date(date.setTime(date.getTime() + 1 * 86400000));
        break;
      case 6:
        day = "Saturday";
        today = new Date(date.setTime(date.getTime() + 1 * 86400000));
        break;
    }

    var nextDay = new Date(today);
    nextDay.setUTCHours(24, 0, 0, 0);

    const Campaign = new Parse.Object.extend("offer");
    const query = new Parse.Query(Campaign);
    query.greaterThanOrEqualTo("createdAt", today);
    query.lessThan("createdAt", nextDay);
    var count = await query.count({ useMasterKey: true });

    data.push({
      name: day,
      count: count
    });
  }

  return data;
};

const getMonthlyCampaigns = async () => {
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  firstDay.setUTCHours(0, 0, 0, 0);
  lastDay.setUTCHours(0, 0, 0, 0);
  var data = [];
  var currentDate = firstDay;
  while (currentDate <= lastDay) {
    var nextDay = new Date(currentDate);
    nextDay.setUTCHours(24, 0, 0, 0);

    const Campaign = new Parse.Object.extend("offer");
    const query = new Parse.Query(Campaign);
    query.greaterThanOrEqualTo("createdAt", currentDate);
    query.lessThan("createdAt", nextDay);
    var count = await query.count({ useMasterKey: true });

    data.push({
      name: currentDate.getUTCDate(),
      count: count
    });

    currentDate = nextDay;
  }

  return data;
};

const getYearlyCampaigns = async () => {
  var data = [];
  var date = new Date();
  var year = date.getFullYear();
  for (var x = 0; x < 12; x++) {
    var startDate;
    var endDate;
    var month = "January";
    switch (x) {
      case 0:
        startDate = new Date(year, 0, 1, 0, 0, 0, 0);
        endDate = new Date(year, 0, 31, 0, 0, 0, 0);
        month = "January";
        break;
      case 1:
        month = "February";
        startDate = new Date(year, 1, 1, 0, 0, 0, 0);
        endDate = new Date(year, 1, 28, 0, 0, 0, 0);
        break;
      case 2:
        month = "March";
        startDate = new Date(year, 2, 1, 0, 0, 0, 0);
        endDate = new Date(year, 2, 31, 0, 0, 0, 0);
        break;
      case 3:
        month = "April";
        startDate = new Date(year, 3, 1, 0, 0, 0, 0);
        endDate = new Date(year, 3, 30, 0, 0, 0, 0);
        break;
      case 4:
        month = "May";
        startDate = new Date(year, 4, 1, 0, 0, 0, 0);
        endDate = new Date(year, 4, 31, 0, 0, 0, 0);
        break;
      case 5:
        month = "June";
        startDate = new Date(year, 5, 1, 0, 0, 0, 0);
        endDate = new Date(year, 5, 30, 0, 0, 0, 0);
        break;
      case 6:
        month = "July";
        startDate = new Date(year, 6, 1, 0, 0, 0, 0);
        endDate = new Date(year, 6, 31, 0, 0, 0, 0);
        break;
      case 7:
        month = "August";
        startDate = new Date(year, 7, 1, 0, 0, 0, 0);
        endDate = new Date(year, 7, 31, 0, 0, 0, 0);
        break;
      case 8:
        month = "September";
        startDate = new Date(year, 8, 1, 0, 0, 0, 0);
        endDate = new Date(year, 8, 30, 0, 0, 0, 0);
        break;
      case 9:
        month = "October";
        startDate = new Date(year, 9, 1, 0, 0, 0, 0);
        endDate = new Date(year, 9, 31, 0, 0, 0, 0);
        break;
      case 10:
        month = "November";
        startDate = new Date(year, 10, 1, 0, 0, 0, 0);
        endDate = new Date(year, 10, 30, 0, 0, 0, 0);
        break;
      case 11:
        month = "December";
        startDate = new Date(year, 11, 1, 0, 0, 0, 0);
        endDate = new Date(year, 11, 31, 0, 0, 0, 0);
        break;
    }

    const Campaign = new Parse.Object.extend("offer");
    const query = new Parse.Query(Campaign);
    query.greaterThanOrEqualTo("createdAt", startDate);
    query.lessThan("createdAt", endDate);
    var count = await query.count({ useMasterKey: true });

    data.push({
      name: month,
      count: count
    });
  }

  return data;
};

const getWeeklyRecipients = async () => {
  var date = getSunday(new Date());
  date.setUTCHours(0, 0, 0, 0);
  var data = [];
  for (var x = 0; x < 7; x++) {
    var today;
    var day = "Sunday";
    switch (x) {
      case 0:
        today = date;
        day = "Sunday";
        break;
      case 1:
        day = "Monday";
        today = new Date(date.setTime(date.getTime() + 1 * 86400000));
        break;
      case 2:
        day = "Tuesday";
        today = new Date(date.setTime(date.getTime() + 1 * 86400000));
        break;
      case 3:
        day = "Wednesday";
        today = new Date(date.setTime(date.getTime() + 1 * 86400000));
        break;
      case 4:
        day = "Thursday";
        today = new Date(date.setTime(date.getTime() + 1 * 86400000));
        break;
      case 5:
        day = "Friday";
        today = new Date(date.setTime(date.getTime() + 1 * 86400000));
        break;
      case 6:
        day = "Saturday";
        today = new Date(date.setTime(date.getTime() + 1 * 86400000));
        break;
    }

    var nextDay = new Date(today);
    nextDay.setUTCHours(24, 0, 0, 0);

    const Recipient = new Parse.Object.extend("recipients");
    const query = new Parse.Query(Recipient);
    query.greaterThanOrEqualTo("createdAt", today);
    query.lessThan("createdAt", nextDay);
    var count = await query.count({ useMasterKey: true });

    data.push({
      name: day,
      count: count
    });
  }

  return data;
};

const getMonthlyRecipient = async () => {
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  firstDay.setUTCHours(0, 0, 0, 0);
  lastDay.setUTCHours(0, 0, 0, 0);
  var data = [];
  var currentDate = firstDay;
  while (currentDate <= lastDay) {
    var nextDay = new Date(currentDate);
    nextDay.setUTCHours(24, 0, 0, 0);

    const Recipient = new Parse.Object.extend("recipients");
    const query = new Parse.Query(Recipient);
    query.greaterThanOrEqualTo("createdAt", currentDate);
    query.lessThan("createdAt", nextDay);
    var count = await query.count({ useMasterKey: true });

    data.push({
      name: currentDate.getUTCDate(),
      count: count
    });

    currentDate = nextDay;
  }

  return data;
};

const getYearlyRecipient = async () => {
  var data = [];
  var date = new Date();
  var year = date.getFullYear();
  for (var x = 0; x < 12; x++) {
    var startDate;
    var endDate;
    var month = "January";
    switch (x) {
      case 0:
        startDate = new Date(year, 0, 1, 0, 0, 0, 0);
        endDate = new Date(year, 0, 31, 0, 0, 0, 0);
        month = "January";
        break;
      case 1:
        month = "February";
        startDate = new Date(year, 1, 1, 0, 0, 0, 0);
        endDate = new Date(year, 1, 28, 0, 0, 0, 0);
        break;
      case 2:
        month = "March";
        startDate = new Date(year, 2, 1, 0, 0, 0, 0);
        endDate = new Date(year, 2, 31, 0, 0, 0, 0);
        break;
      case 3:
        month = "April";
        startDate = new Date(year, 3, 1, 0, 0, 0, 0);
        endDate = new Date(year, 3, 30, 0, 0, 0, 0);
        break;
      case 4:
        month = "May";
        startDate = new Date(year, 4, 1, 0, 0, 0, 0);
        endDate = new Date(year, 4, 31, 0, 0, 0, 0);
        break;
      case 5:
        month = "June";
        startDate = new Date(year, 5, 1, 0, 0, 0, 0);
        endDate = new Date(year, 5, 30, 0, 0, 0, 0);
        break;
      case 6:
        month = "July";
        startDate = new Date(year, 6, 1, 0, 0, 0, 0);
        endDate = new Date(year, 6, 31, 0, 0, 0, 0);
        break;
      case 7:
        month = "August";
        startDate = new Date(year, 7, 1, 0, 0, 0, 0);
        endDate = new Date(year, 7, 31, 0, 0, 0, 0);
        break;
      case 8:
        month = "September";
        startDate = new Date(year, 8, 1, 0, 0, 0, 0);
        endDate = new Date(year, 8, 30, 0, 0, 0, 0);
        break;
      case 9:
        month = "October";
        startDate = new Date(year, 9, 1, 0, 0, 0, 0);
        endDate = new Date(year, 9, 31, 0, 0, 0, 0);
        break;
      case 10:
        month = "November";
        startDate = new Date(year, 10, 1, 0, 0, 0, 0);
        endDate = new Date(year, 10, 30, 0, 0, 0, 0);
        break;
      case 11:
        month = "December";
        startDate = new Date(year, 11, 1, 0, 0, 0, 0);
        endDate = new Date(year, 11, 31, 0, 0, 0, 0);
        break;
    }

    const Recipient = new Parse.Object.extend("recipients");
    const query = new Parse.Query(Recipient);
    query.greaterThanOrEqualTo("createdAt", startDate);
    query.lessThan("createdAt", endDate);
    var count = await query.count({ useMasterKey: true });

    data.push({
      name: month,
      count: count
    });
  }

  return data;
};

function* loadData(action) {
  var userCount = yield call(countUsers);
  var campaignCount = yield call(monthlyCampaignCount);
  var sentCount = yield call(monthlySentCount);
  yield put(
    setInitial({
      totalUsers: userCount,
      monthlyCampaigns: campaignCount,
      monthlySent: sentCount
    })
  );
}

function* getCampaignData(action) {
  var type = action.payload;
  switch (type) {
    case "week":
      var data = yield call(getWeeklyCampaigns);
      yield put(setCampaignData(data));
      var rData = yield call(getWeeklyRecipients);
      yield put(setRecipientData(rData));
      break;
    case "month":
      var data = yield call(getMonthlyCampaigns);
      yield put(setCampaignData(data));
      var rData = yield call(getMonthlyRecipient);
      yield put(setRecipientData(rData));
      break;
    case "year":
      var data = yield call(getYearlyCampaigns);
      yield put(setCampaignData(data));
      var rData = yield call(getYearlyRecipient);
      yield put(setRecipientData(rData));
      break;
  }
}

function* dashboardSaga() {
  yield takeLatest(LOAD_DATA, loadData);
  yield takeLatest(GET_CAMPAIGN_DATA, getCampaignData);
}

export default dashboardSaga;
