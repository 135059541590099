import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { colors } from "../../config";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Divider,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from "@material-ui/core";

import { createStructuredSelector } from "reselect";
import {
  makeSelectDeleteError,
  makeSelectPasswordError,
  makeSelectShowSnackbar,
  makeSelectSnackbarMessage
} from "./ducks/selectors";
import {
  setDeleteError,
  setPasswordError,
  deleteUser,
  changePassword,
  setSuccessSnackbar
} from "./ducks/actions";

import ErrorSnackbar from "../../components/ErrorSnackbar";
import SuccessSnackbar from "../../components/SuccessSnackbar";

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 25
  },
  header: {
    marginTop: 10,
    marginBottom: 3
  },
  info: {
    marginTop: 25
  },
  subheaders: {
    marginTop: 20
  },
  deleteButton: {
    backgroundColor: colors.error,
    color: colors.white,
    width: 150,
    height: 55,
    marginTop: 15
  },
  passwordButton: {
    backgroundColor: colors.primary,
    color: colors.white,
    width: 150,
    height: 55,
    marginTop: 15
  },
  section: {
    marginTop: 30,
    paddingLeft: 20,
    paddingRight: 20
  },
  deleteDialogButton: {
    color: colors.error
  }
});

const mapStateToProps = createStructuredSelector({
  deleteError: makeSelectDeleteError(),
  passwordError: makeSelectPasswordError(),
  showSuccess: makeSelectShowSnackbar(),
  successMessage: makeSelectSnackbarMessage()
});

const mapDispatchToProps = dispatch => ({
  setDeleteError: payload => dispatch(setDeleteError(payload)),
  setPasswordError: payload => dispatch(setPasswordError(payload)),
  deleteUser: payload => dispatch(deleteUser(payload)),
  changePassword: payload => dispatch(changePassword(payload)),
  setSuccessSnackbar: payload => dispatch(setSuccessSnackbar(payload))
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class Users extends Component {
  state = {
    deleteEmail: null,
    changeEmail: null,
    changePassword: null,
    openError: false,
    errorMessage: "",
    showDialog: false
  };

  handleOnDeleteClick = () => {
    const email = this.state.deleteEmail;
    if (email == null || email == "") {
      // Show Snackbar Error
      this.setState({
        openError: true,
        errorMessage: "Must Enter an Email Address",
        showDialog: false
      });
    } else if (email == "admin@justspeakeasy.com") {
      this.setState({
        openError: true,
        errorMessage: "Do Not Be a Dumbass",
        showDialog: false
      });
    } else {
      this.setState({
        showDialog: false
      })
      this.props.deleteUser({ email: email });
    }
  };

  handleOnPasswordClick = () => {
    const email = this.state.changeEmail;
    const password = this.state.changePassword;
    if (email == null || email == "") {
      // Show Snackbar Error
      this.setState({
        openError: true,
        errorMessage: "Must Enter an Email Address",
      });
    } else if (email == "admin@justspeakeasy.com") {
      this.setState({
        openError: true,
        errorMessage: "No.",
      });
    } else if (password == null || password == "" || password.length < 6) {
      this.setState({
        openError: true,
        errorMessage: "Must Enter a Valid Password",
      });
    } else {
      this.props.changePassword({ email: email, password: password });
    }
  };

  handleDeleteEmail = value => {
    this.setState({
      deleteEmail: value
    });
    this.props.setDeleteError(false);
  };

  handleChangeEmail = value => {
    this.setState({
      changeEmail: value
    });
    this.props.setPasswordError(false);
  };

  handleChangePassword = value => {
    this.setState({
      changePassword: value
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      openError: false,
      errorMessage: ""
    });
  };

  handleSuccessClose = () => {
    this.props.setSuccessSnackbar({
      show: false,
      message: ""
    });
  };

  handleOpenDialog = () => {
    this.setState({
      showDialog: true
    });
  };

  handleCloseDialog = () => {
    this.setState({
      showDialog: false
    });
  };

  render() {
    const {
      classes,
      deleteError,
      passwordError,
      showSuccess,
      successMessage
    } = this.props;
    const { openError, errorMessage, showDialog } = this.state;

    return (
      <div className={classes.root}>
        <Typography className={classes.header} variant="h5">
          Users
        </Typography>
        <Divider />
        <div className={classes.section}>
          <Typography className={classes.subheaders} variant="h6">
            Delete a User
          </Typography>
          <Typography variant="subtitle1">
            This will permanently remove a User from both Parse and Firebase.
            Once this has been done, it CANNOT be undone.
          </Typography>
          <Grid container spacing={16}>
            <Grid item xs={4}>
              <TextField
                id="email"
                label="Email"
                type="email"
                autoComplete="email"
                fullWidth
                className={classes.textField}
                value={this.state.name}
                variant="outlined"
                margin="normal"
                onChange={e => this.handleDeleteEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                className={classes.deleteButton}
                onClick={this.handleOpenDialog}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
          {deleteError && (
            <Typography variant="subtitle2" color="error">
              This email was not found.
            </Typography>
          )}
        </div>
        <div className={classes.section}>
          <Typography className={classes.subheaders} variant="h6">
            Reset Password
          </Typography>
          <Typography variant="subtitle1">
            Change a password. This CANNOT be undone. This will remove the old
            password.
          </Typography>
          <Grid container spacing={16}>
            <Grid item xs={4}>
              <TextField
                id="email"
                label="Email"
                type="email"
                autoComplete="email"
                fullWidth
                className={classes.textField}
                value={this.state.name}
                variant="outlined"
                margin="normal"
                onChange={e => this.handleChangeEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="password"
                label="New Password"
                type="text"
                fullWidth
                className={classes.textField}
                value={this.state.name}
                variant="outlined"
                margin="normal"
                onChange={e => this.handleChangePassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                className={classes.passwordButton}
                onClick={() => this.handleOnPasswordClick()}
              >
                Change
              </Button>
            </Grid>
          </Grid>
          {passwordError && (
            <Typography variant="subtitle2" color="error">
              This email was not found.
            </Typography>
          )}
          <ErrorSnackbar
            open={openError}
            onClose={() => this.handleSnackbarClose()}
            message={errorMessage}
          />
          <SuccessSnackbar
            open={showSuccess}
            onClose={() => this.handleSuccessClose()}
            message={successMessage}
          />
        </div>
        <Dialog
          open={showDialog}
          TranistionComponent={Transition}
          keepMounted
          onClose={this.handleCloseDialog}
        >
          <DialogTitle>Delete This User?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this user? It can <b>NOT</b> be
              undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog}>Cancel</Button>
            <Button onClick={() => this.handleOnDeleteClick()} className={classes.deleteDialogButton}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(withStyles(styles)(Users));
