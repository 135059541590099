import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import globalReducer from "../../ducks/reducer";
import loginReducer from "../../views/Login/ducks/reducer";
import userReducer from "../../views/Users/ducks/reducer";
import dashboardReducer from "../../views/Dashboard/ducks/reducer";

const appReducer = combineReducers({
  globalState: globalReducer,
  loginState: loginReducer,
  userState: userReducer,
  dashboardState: dashboardReducer
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
