import { call, put, takeLatest } from "redux-saga/effects";
import ParseReact from "parse-react";
import Parse from "parse";

import { LOG_IN } from "./constants";
import { loginFailed } from "./actions";
import { loginSuccess } from "../../../ducks/actions";

const signIn = async data => {
  return await await Parse.User.logIn(
    data.email,
    data.password
  ).then(
    function() {
      return true;
    },
    function() {
      return false;
    }
  );
};

function* login(action) {
  var email = action.payload.email;
  var password = action.payload.password;
  if(email == "admin@justspeakeasy.com"){
    var status = yield call(signIn, {
      email: action.payload.email,
      password: action.payload.password
    });

    if (status) {
      yield put(loginSuccess());
    } else {
      yield put(loginFailed());
    }
  }
  else{
    yield put(loginFailed());
  }
}

function* loginSaga() {
  yield takeLatest(LOG_IN, login);
}

export default loginSaga;
