import { call, put, takeLatest } from "redux-saga/effects";
import ParseReact from "parse-react";
import Parse from "parse";

import { DELETE_USER, CHANGE_PASSWORD } from "./constants";
import {
  setDeleteError,
  setPasswordError,
  setSuccessSnackbar
} from "./actions";

const checkIfUserExists = async email => {
  const query = new Parse.Query(Parse.User);
  query.equalTo("email", email);
  var user = await query.first({useMasterKey: true});
  if (user != null && user != "undefined") {
    return true;
  } else {
    return false;
  }
};

const deleteUserFromParse = async email => {
  const query = new Parse.Query(Parse.User);
  query.equalTo("email", email);
  var user = await query.first({useMasterKey: true});
  await user
    .destroy({
      useMasterKey: true
    })
    .then(
      user => {
        return true;
      },
      error => {
        return false;
      }
    );
};

const deleteUserFromFirebase = async email => {
  const params = { email: email };
  return await Parse.Cloud.run("User:removeFirebaseAuth", params, { useMasterKey: true });
};

const parseChangePassword = async data => {
  const query = new Parse.Query(Parse.User);
  query.equalTo("email", data.email);
  var user = await query.first({useMasterKey: true});
  user.set("password", data.password);
  try {
    return await user.save(null, { useMasterKey: true }).then(function() {
      return true;
    });
  } catch (error) {
    return false;
  }
};

function* deleteUser(action) {
  var email = action.payload.email;
  var status = yield call(checkIfUserExists, email);

  if (status) {
    var parseDelete = yield call(deleteUserFromParse, email);
    var firebaseResult = yield call(deleteUserFromFirebase, email);
    yield put(setSuccessSnackbar({ show: true, message: "User Deleted" }));
  } else {
    yield put(setDeleteError(true));
  }
}

function* changePassword(action) {
  var email = action.payload.email;
  var password = action.payload.password;
  var status = yield call(checkIfUserExists, email);
  if (status) {
    var changed = yield call(parseChangePassword, {
      email: email,
      password: password
    });
    if(changed){
      yield put(setSuccessSnackbar({ show: true, message: "Password Changed" }));
    } else{
      // TODO Error
    }
  } else {
    yield put(setPasswordError(true));
  }
}

function* userSaga() {
  yield takeLatest(DELETE_USER, deleteUser);
  yield takeLatest(CHANGE_PASSWORD, changePassword);
}

export default userSaga;
