import React, { Component } from "react";
import { colors } from "../../config";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Divider,
  Grid,
  TextField,
  Button
} from "@material-ui/core";

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 25
  },
  header: {
    marginTop: 10,
    marginBottom: 3
  },
  info: {
    marginTop: 25
  },
  subheaders: {
    marginTop: 20
  },
  saveButton: {
    backgroundColor: colors.primary,
    color: colors.white,
    width: 150,
    height: 55,
    marginTop: 15
  },
  section: {
    marginTop: 30,
    paddingLeft: 20,
    paddingRight: 20
  }
});

class Pricing extends Component {
  state = {};

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Typography className={classes.header} variant="h5">
          Pricing
        </Typography>
        <Divider />
        <Typography variant="subtitle1">
          WARNING! This page is not enabled with Stripe yet! Changing details
          here will update ROR but the Stripe plans will need to be updated
          before a user is charged correctly.
        </Typography>
        <div className={classes.section}>
          <Typography className={classes.subheaders} variant="h6">
            Small Subscription Plan
          </Typography>
          <Typography variant="subtitle1">
            Update the name and cost of the Small Subscription Plan
          </Typography>
          <Grid container spacing={16}>
            <Grid item xs={3}>
              <TextField
                id="email"
                label="Name"
                type="text"
                fullWidth
                className={classes.textField}
                value={this.state.name}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={2}>
              <Button variant="contained" className={classes.saveButton}>
                Save
              </Button>
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="email"
                label="Cost"
                type="number"
                fullWidth
                className={classes.textField}
                value={this.state.name}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={2}>
              <Button variant="contained" className={classes.saveButton}>
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className={classes.section}>
          <Typography className={classes.subheaders} variant="h6">
            Medium Subscription Plan
          </Typography>
          <Typography variant="subtitle1">
            Update the name and cost of the Medium Subscription Plan
          </Typography>
          <Grid container spacing={16}>
            <Grid item xs={3}>
              <TextField
                id="email"
                label="Name"
                type="text"
                fullWidth
                className={classes.textField}
                value={this.state.name}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={2}>
              <Button variant="contained" className={classes.saveButton}>
                Save
              </Button>
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="email"
                label="Cost"
                type="number"
                fullWidth
                className={classes.textField}
                value={this.state.name}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={2}>
              <Button variant="contained" className={classes.saveButton}>
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className={classes.section}>
          <Typography className={classes.subheaders} variant="h6">
            Large Subscription Plan
          </Typography>
          <Typography variant="subtitle1">
            Update the name and cost of the Large Subscription Plan
          </Typography>
          <Grid container spacing={16}>
            <Grid item xs={3}>
              <TextField
                id="email"
                label="Name"
                type="text"
                fullWidth
                className={classes.textField}
                value={this.state.name}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={2}>
              <Button variant="contained" className={classes.saveButton}>
                Save
              </Button>
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="email"
                label="Cost"
                type="number"
                fullWidth
                className={classes.textField}
                value={this.state.name}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={2}>
              <Button variant="contained" className={classes.saveButton}>
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Pricing);
