import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { colors } from "../../config";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Divider,
  Grid,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@material-ui/core";

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line
} from "recharts";

import { createStructuredSelector } from "reselect";
import {
  makeSelectTotalUsers,
  makeSelectMonthlyCampaigns,
  makeSelectMonthlySent,
  makeSelectCampaignData,
  makeSelectRecipientData
} from "./ducks/selectors";
import { initialLoad, getCampaignData } from "./ducks/actions";

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 25
  },
  header: {
    marginTop: 10,
    marginBottom: 3
  },
  centerDiv: {
    textAlign: "center"
  },
  divider: {
    marginBottom: 25
  },
  campaigns: {
    marginTop: 45
  },
  group: {
    display: "flex",
    width: "auto",
    flexDirection: "row",
    justifyContent: "space-around"
  },
  radio: {
    "&$checked": {
      color: colors.primary
    }
  },
  checked: {},
  radioRow: {
    marginBottom: 20
  },
  chartTitle: {
    marginLeft: 125,
    marginBottom: 15
  }
});

const mapStateToProps = createStructuredSelector({
  totalUsers: makeSelectTotalUsers(),
  totalCampaigns: makeSelectMonthlyCampaigns(),
  totalSent: makeSelectMonthlySent(),
  campaignData: makeSelectCampaignData(),
  recipientData: makeSelectRecipientData()
});

const mapDispatchToProps = dispatch => ({
  loadInitial: () => dispatch(initialLoad()),
  getCampaignData: payload => dispatch(getCampaignData(payload))
});

class Dashboard extends Component {
  state = {
    radioValue: "week"
  };

  componentDidMount() {
    if (this.props.totalUsers == 0) {
      this.props.loadInitial();
      this.props.getCampaignData(this.state.radioValue);
    }
  }

  handleRadioClick = value => {
    this.props.getCampaignData(value);
    this.setState({
      radioValue: value
    });
  };

  render() {
    const { classes, totalUsers, totalCampaigns, totalSent } = this.props;

    return (
      <div className={classes.root}>
        <Typography className={classes.header} variant="h5">
          Dashboard
        </Typography>
        <Divider className={classes.divider} />
        <Grid container spacing={16}>
          <Grid item xs={2}>
            <div className={classes.centerDiv}>
              <Typography className={classes.infoHeader} variant="subtitle2">
                Total Users
              </Typography>
              <Typography className={classes.infoText} variant="h3">
                {totalUsers}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.centerDiv}>
              <Typography className={classes.infoHeader} variant="subtitle2">
                Campaigns Created This Month
              </Typography>
              <Typography className={classes.infoText} variant="h3">
                {totalCampaigns}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.centerDiv}>
              <Typography className={classes.infoHeader} variant="subtitle2">
                Sent Offers This Month
              </Typography>
              <Typography className={classes.infoText} variant="h3">
                {totalSent}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Typography className={classes.campaigns} variant="h6">
          Campaign Analysis
        </Typography>
        <Divider className={classes.divider} />
        <div className={classes.radioRow}>
          <FormControl fullWidth component="fieldset">
            <RadioGroup
              aria-label="graph type"
              name="monthlyGraphType"
              value={this.state.radioValue}
              onChange={e => this.handleRadioClick(e.target.value)}
              className={classes.group}
            >
              <FormControlLabel
                value="week"
                control={
                  <Radio
                    classes={{
                      root: classes.radio,
                      checked: classes.checked
                    }}
                  />
                }
                label="Current Week"
              />
              <FormControlLabel
                value="month"
                control={
                  <Radio
                    classes={{
                      root: classes.radio,
                      checked: classes.checked
                    }}
                  />
                }
                label="Month to Date"
              />
              <FormControlLabel
                value="year"
                control={
                  <Radio
                    classes={{
                      root: classes.radio,
                      checked: classes.checked
                    }}
                  />
                }
                label="Year to Date"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <Grid container spacing={16}>
          <Grid item xs={6}>
            <Typography className={classes.chartTitle} variant="subtitle1">
              Campaigns Created
            </Typography>
            <LineChart width={600} height={300} data={this.props.campaignData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="count" />
            </LineChart>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.chartTitle} variant="subtitle1">
              Campaigns Sent
            </Typography>
            <LineChart width={600} height={300} data={this.props.recipientData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="count" />
            </LineChart>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(withStyles(styles)(Dashboard));
