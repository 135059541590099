import * as types from './constants';

export const updatePage = payload => ({
  type: types.UPDATE_PAGE,
  payload
});

export const loginSuccess = () => ({
  type: types.LOG_IN_SUCCESS
});
