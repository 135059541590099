import * as types from "./constants";

const INITIAL_STATE = {
  totalUsers: 0,
  monthlyCampaigns: 0,
  monthlySent: 0,
  campaignData: null,
  recipientData: null
};

const setInitial = (state, action) => ({
  ...state,
  totalUsers: action.payload.totalUsers,
  monthlyCampaigns: action.payload.monthlyCampaigns,
  monthlySent: action.payload.monthlySent
});

const setCampaignData = (state, action) => ({
  ...state,
  campaignData: action.payload
});

const setRecipientData = (state, action) => ({
  ...state,
  recipientData: action.payload
})

function dashboardReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_INITIAL: {
      return setInitial(state, action);
    }
    case types.SET_CAMPAIGN_DATA: {
      return setCampaignData(state, action);
    }
    case types.SET_RECIPIENT_DATA:{
      return setRecipientData(state, action);
    }
    default: {
      return state;
    }
  }
}

export default dashboardReducer;
