import { createSelector } from "reselect";

const selectGlobalState = state => state.globalState;

export const makeSelectPageNumber = () =>
  createSelector(
    selectGlobalState,
    substate => substate.pageNumber
  );

export const makeSelectAuth = () =>
  createSelector(
    selectGlobalState,
    substate => substate.auth
  );
