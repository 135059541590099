import * as types from "./constants";

const INITIAL_STATE = {
  pageNumber: 1,
  auth: false
};

const loadingScreen = state => ({
  ...state,
  loading: true
});

const updatePage = (state, action) => ({
  ...state,
  pageNumber: action.payload
});

const isAuth = (state, action) => ({
  ...state,
  auth: true
});

function globalReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_PAGE: {
      return updatePage(state, action);
    }
    case types.LOG_IN_SUCCESS: {
      return isAuth(state, action);
    }
    default: {
      return state;
    }
  }
}

export default globalReducer;
