import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { colors } from "../../config";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Typography, TextField, Button } from "@material-ui/core";
import Logo from "../../components/images/speak_easy_logo.png";

import { createStructuredSelector } from "reselect";
import { makeSelectErrorStatus } from "./ducks/selectors";

import { login } from "./ducks/actions";

const mapStateToProps = createStructuredSelector({
  showError: makeSelectErrorStatus()
});

const mapDispatchToProps = dispatch => ({
  attemptLogin: payload => dispatch(login(payload))
});

const styles = theme => ({
  root: {
    backgroundColor: colors.secondaryDark,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    width: "100%",
    height: "100%"
  },
  paper: {
    position: "absolute",
    margin: "auto",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: "40%",
    height: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      height: "50%"
    },
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    paddingBottom: 20
  },
  logo: {
    width: "30%"
  },
  centerDiv: {
    textAlign: "center"
  },
  button: {
    backgroundColor: colors.primary,
    color: colors.white,
    marginTop: 10
  }
});

class Login extends Component {
  state = {
    emailError: false,
    passwordError: false,
    password: null,
    email: null
  };

  handleSubmit = () => {
    var email = this.state.email;
    var password = this.state.password;
    if (email == null || email === "") {
      this.setState({
        emailError: true
      });
    } else if (password == null || password === "") {
      this.setState({
        passwordError: true
      });
    } else {
      this.props.attemptLogin({ email, password });
    }
  };

  handleOnPasswordChange = event => {
    this.setState({
      password: event.target.value,
      passwordError: false
    });
  };

  handleOnEmailChange = event => {
    this.setState({
      email: event.target.value,
      emailError: false
    });
  };

  render() {
    const { classes } = this.props;
    const { emailError, passwordError } = this.state;

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.centerDiv}>
            <img className={classes.logo} src={Logo} />
          </div>
          <div className={classes.centerDiv}>
            <Typography variant="h6">Administrative Login</Typography>
          </div>
          <TextField
            id="email"
            label="Email"
            type="email"
            error={emailError}
            autoComplete="email"
            fullWidth
            className={classes.textField}
            value={this.state.name}
            variant="outlined"
            margin="normal"
            onChange={e => this.handleOnEmailChange(e)}
          />
          <TextField
            id="password"
            label="Password"
            type="password"
            error={passwordError}
            fullWidth
            className={classes.textField}
            value={this.state.name}
            variant="outlined"
            margin="normal"
            onChange={e => this.handleOnPasswordChange(e)}
          />
          <Typography
            className={classes.failed}
            color="error"
            style={{
              visibility: this.props.showError ? "visible" : "hidden"
            }}
          >
            Login Failed.
          </Typography>
          <div className={classes.centerDiv}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => this.handleSubmit()}
            >
              Submit
            </Button>
          </div>
        </Paper>
      </div>
    );
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(withStyles(styles)(Login));
